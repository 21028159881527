<template>
    <div class="page-digital-comms-template-general">
        <div class="row">
            <div class="col-6 col-mob-12 box">
                <div class="board">
                    <h3 class="heading">General</h3>
                    
                    <app-loader v-if="processing.general"></app-loader>

                    <app-error v-model="errors.general.save"></app-error>

                    <app-dropdown-select
                        v-model="template_id"
                        :options="digital_comms_templates_ids"

                        label="Template ID"

                        key-value="TemplateID"
                        key-title="Title"

                        :error="errors.general.SelectedTemplateID"
                        @select="errors.general.SelectedTemplateID = null"

                        @change="onChangeTemplateID"
                    ></app-dropdown-select>

                    <app-input v-if="template_id == -1" v-model="current_obj.TemplateID"  label="Template ID" type="text"  :error="errors.general.TemplateID"  @change="errors.general.TemplateID = null"  :disabled="processing.general"></app-input>
                    <app-input                          v-model="current_obj.Description" label="Description" type="text"  :error="errors.general.Description" @change="errors.general.Description = null"  :disabled="processing.general"></app-input>

                    <button class="btn btn-small btn-primary" @click="submit('general')" :disabled="processing.general || !is_changed_general">Submit</button>
                </div>
            </div>


            <div class="col-6 col-mob-12 box">
                <div class="board">
                    <h3 class="heading">SMS</h3>
                    
                    <app-loader v-if="processing.sms"></app-loader>

                    <app-error v-model="errors.sms.save"></app-error>

                    <app-input v-model="current_obj.SourceSMSName" label="SMS from name (*not used if replies enabled)"   type="text" :error="errors.sms.SourceSMSName"   @change="errors.sms.SourceSMSName = null"   :disabled="processing.sms"></app-input>
                    <app-input v-model="current_obj.SourceSMS"     label="SMS from number" type="tel"  :error="errors.sms.SourceSMS" @change="errors.sms.SourceSMS = null" :disabled="processing.sms"></app-input>

                    <button class="btn btn-small btn-primary" @click="submit('sms')" :disabled="processing.sms || !is_changed_sms">Submit</button>
                </div>
            </div>

            <div class="col-6 col-mob-12 box">
                <div class="board">
                    <h3 class="heading">Email</h3>
                    
                    <app-loader v-if="processing.email"></app-loader>

                    <app-error v-model="errors.email.save"></app-error>

                    <app-input v-model="current_obj.SourceEmailSubject" label="Email subject line" type="text"  :error="errors.email.SourceEmailSubject" @change="errors.email.SourceEmailSubject = null" :disabled="processing.email"></app-input>
                    <app-input v-model="current_obj.SourceEmailName"    label="Email from name"    type="text"  :error="errors.email.SourceEmailName"    @change="errors.email.SourceEmailName = null"    :disabled="processing.email"></app-input>
                    <app-input v-model="current_obj.SourceEmail"        label="Email from address" type="email" :error="errors.email.SourceEmail"        @change="errors.email.SourceEmail = null"        :disabled="processing.email"></app-input>
                    <app-input v-model="current_obj.SourceEmailAlias"   label="Email from alias"   type="email" :error="errors.email.SourceEmailAlias"   @change="errors.email.SourceEmailAlias = null"   :disabled="processing.email"></app-input>

                    <button class="btn btn-small btn-primary" @click="submit('email')" :disabled="processing.email || !is_changed_email">Submit</button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

import appLoader from '@/components/app-loader'
import appError from '@/components/app-error'

import appInput from '@/components/app-input'
import appDropdownSelect from '@/components/app-dropdown-select'

import DigitalCommsTemplatesValidator from '@/validators/digital-comms-templates-validator'
import errMessage from '@/helpers/errMessage'

export default {
    components: {
        appLoader,
        appError,

        appInput,
        appDropdownSelect,
    },

    data() {
        return {
            template_id: null,

            current_obj: {},
            original_obj: {},

            errors: {
                general: {},
                email: {},
                sms: {},
            },

            processing: {
                general: false,
                email: false,
                sms: false,
            },
        }
    },

    created() {
        this.init()
    },

    methods: {
        init() {
            this.processing['general'] = true
            this.processing['email'] = true
            this.processing['sms'] = true

            const getCachedNotificationTemplateByUUID = () => this.$store.dispatch('api_notification/getCachedNotificationTemplateByUUID', { SPID: this.current_spid, UUID: this.uuid }).then((template) => {
                this.original_obj = { ...template }

                this.template_id = template.TemplateID ?
                    (
                        this.digital_comms_templates_ids.findIndex(id => id.TemplateID == template.TemplateID) > -1
                            ? template.TemplateID
                            : -1
                    )
                    : 0

                this.current_obj = { ...this.original_obj }

                this.errors['general'].save = null
                this.errors['email'].save = null
                this.errors['sms'].save = null

                this.processing['general'] = false
                this.processing['email'] = false
                this.processing['sms'] = false
            }).catch(error => {
                this.errors['general'].save = null
                this.errors['email'].save = null
                this.errors['sms'].save = null

                this.processing['general'] = false
                this.processing['email'] = false
                this.processing['sms'] = false
            })

            this.$store.dispatch('getDigitalCommsTemplatesIDs')
                .then(getCachedNotificationTemplateByUUID)
                .catch(getCachedNotificationTemplateByUUID)
        },

        onChangeTemplateID(template_id) {
            if (template_id == -1) {
                this.current_obj.TemplateID = this.original_obj.TemplateID
            } else {
                this.processing.general = true

                this.$store.dispatch('api_notification/FindNotificationTemplatesPaginated', { SPID: this.current_spid, TemplateID: template_id }).then(response => {
                    if (response.NotificationTemplates.length && response.NotificationTemplates[0].UUID != this.uuid) {
                        this.$set(this.errors.general, 'SelectedTemplateID', 'Selected TemplateID currently exists')
                    } else {
                        this.current_obj.TemplateID = +template_id
                    }

                    this.processing.general = false
                }).catch(error => {
                    this.$set(this.errors.general, 'SelectedTemplateID', errMessage(error))
                    this.processing.general = true
                })
            }
        },

        submit(target) {
            if (this.validation(target)) {
                this.processing[target] = true

                let payload = {}

                switch (target) {
                    case 'general': {
                        payload = {
                            TemplateID: Number(this.current_obj.TemplateID),
                            Description: this.current_obj.Description,
                        }
                    } break

                    case 'email': {
                        payload = {
                            SourceEmailName: this.current_obj.SourceEmailName,
                            SourceEmail: this.current_obj.SourceEmail,
                            SourceEmailAlias: this.current_obj.SourceEmailAlias,
                            SourceEmailSubject: this.current_obj.SourceEmailSubject,
                        }
                    } break

                    case 'sms': {
                        payload = {
                            SourceSMSName: this.current_obj.SourceSMSName,
                            SourceSMS: this.current_obj.SourceSMS,
                        }
                    } break
                }

                this.$store.dispatch('api_notification/UpdateNotificationTemplate', {
                    SPID: this.current_spid,
                    UUID: this.uuid,
                    ...payload,
                }).then((obj) => {
                    this.original_obj = obj
                    this.processing[target] = false
                }).catch(error => {
                    this.errors[target].save = errMessage(error)

                    this.processing[target] = false
                })
            } else {
                console.log('target', target, 'failed validation with:', this.validation(target))
            }
        },

        validation(target) {
            let is_valid = true
            this.errors[target] = {}

            let values = {}

            switch (target) {
                case 'general': {
                    if (this.template_id) {
                        if (this.template_id == -1 && this.current_obj.TemplateID < 1000) {
                            this.$set(this.errors[target], 'TemplateID', 'Custom Template ID must be >= 1000')
                            is_valid = false
                        } else {        
                            values = {
                                TemplateID: this.current_obj.TemplateID,
                                Description: this.current_obj.Description,
                            }
                        }
                    } else {
                        this.$set(this.errors[target], 'SelectedTemplateID', 'Please choose the TemplateID')
                        is_valid = false
                    }
                } break

                case 'email': {
                    values = {
                        SourceEmailName: this.current_obj.SourceEmailName,
                        SourceEmail: this.current_obj.SourceEmail,
                        SourceEmailAlias: this.current_obj.SourceEmailAlias,
                        SourceEmailSubject: this.current_obj.SourceEmailSubject,
                    }
                } break

                case 'sms': {
                    values = {
                        SourceSMSName: this.current_obj.SourceSMSName,
                        SourceSMS: this.current_obj.SourceSMS,
                    }
                } break
            }

            for (const field in values) {
                if (DigitalCommsTemplatesValidator.isRuleExists(field)) {
                    let thisError = DigitalCommsTemplatesValidator.isInvalid(field, values[field])
                    if (thisError) {
                        console.log('target', target, 'field', field, '', thisError)
                        this.$set(this.errors[target], field, thisError)
                        is_valid = false
                    }

                    // if (DigitalCommsTemplatesValidator.isInvalid(field, values[field])) {
                    //     is_valid = false
                    //     this.$set(this.errors[target], field,  DigitalCommsTemplatesValidator.getError())
                    // }
                }
            }

            return is_valid
        },
    },

    computed: {
        ...mapGetters([
            'getDigitalCommsTemplateByUUID',
            'current_spid',

            'digital_comms_templates_ids',
        ]),

        uuid() {
            return this.$route.params.uuid
        },

        is_changed_general() {
            return this.current_obj.TemplateID != this.original_obj.TemplateID
                || this.current_obj.Description != this.original_obj.Description
        },
        is_changed_email() {
            return this.current_obj.SourceEmailName != this.original_obj.SourceEmailName
                || this.current_obj.SourceEmailSubject != this.original_obj.SourceEmailSubject
                || this.current_obj.SourceEmail != this.original_obj.SourceEmail
                || this.current_obj.SourceEmailAlias != this.original_obj.SourceEmailAlias
        },
        is_changed_sms() {
            return this.current_obj.SourceSMSName != this.original_obj.SourceSMSName
                || this.current_obj.SourceSMS != this.original_obj.SourceSMS
        },
    },
}
</script>

<style lang="scss">
.page-digital-comms-template-general {
    .row {
        .box {
            margin-bottom: 24px;
        }
    }
    .board {
        padding: 24px;
        border-radius: $border-radius-secondary;
        background: var(--color-component-bg-primary);
        box-shadow: var(--box-shadow-primary);
        position: relative;

        .app-error {
            margin-top: 16px;
        }

        .app-input,
        .app-dropdown-select {
            margin: 24px 0 0;
        }

        &>.btn {
            margin-top: 32px;
        }
    }
}

@media (max-width: $tablet-size) {
    .page-digital-comms-template-general {
        .board {
            padding: 16px;
            
            &>.btn {
                margin-top: 32px;
            }
        }
    }
}

@media (max-width: $mobile-size) {
    .page-digital-comms-template-general {
        .board {
            padding: 16px 8px 8px;

            &>.btn {
                max-width: 100%;
            }
        }
    }
}
</style>